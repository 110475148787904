<template>
  <v-container fluid>
    <v-row class="align-center justify-space-between">
      <v-row>
        <v-col cols="auto">
          <v-select
            v-model="filters.expense_role"
            :label="$t('Expense type')"
            :items="expenseRolesList"
            outlined
            dense
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="auto" md="2">
          <v-text-field
            v-model="filters.search"
            :label="$t('Search')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
      </v-row>

      <v-col cols="12" sm="auto">
        <v-btn small color="primary" @click="showExpenseTypeModal(null)">
          <v-icon>mdi-plus</v-icon>
          {{ $t('Add Expense Type') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="headers"
        :items-per-page="30"
        :items="filteredItems"
        class="elevation-1 w-100 mx-3"
      >
        <template #item.amount="{ item }">
          <span v-if="item.units === 'dynamic'">
            {{ `${item.quantifier || ''}` }}
          </span>
          <span v-else>
            {{ item.amount }}
          </span>
        </template>
        <template #item.channels="{ item }">
          <v-chip-group>
            <template v-if="item.channels && item.channels.length">
              <v-chip
                v-for="channel in item.channels"
                :key="channel"
                small
                class="mr-1"
                color="primary"
                text-color="white"
              >
                {{ channel }}
              </v-chip>
            </template>
            <template v-else>
              <span class="grey--text">All channels</span>
            </template>
          </v-chip-group>
        </template>
        <template #item.actions="{ item }">
          <v-btn small text fab @click="showExpenseTypeModal(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <confirmation-modal
            text="Are you sure you want
              to delete this Expense Type?"
            @action="removeSelctedExpenseType(item.id)"
          >
            <template #activator="{ on }">
              <v-btn small text fab v-on="on">
                <v-icon small>$trash</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  name: 'ExpenseTypes',
  components: {
    ConfirmationModal,
  },
  props: ['businessModelId'],
  computed: {
    ...mapGetters('reservation', ['channelsItems']),
    headers() {
      const baseHeaders = [
        { text: 'Expense type', value: 'name', sortable: false },
        { text: 'P&L category', value: 'category', sortable: false },
        { text: 'Type', value: 'expense_role', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
      ]

      if (this.businessModelId) {
        baseHeaders.push({
          text: 'Channels',
          value: 'channels',
          sortable: false,
        })
      }

      baseHeaders.push({
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      })

      return baseHeaders
    },
    expenseRolesList() {
      return ['Business', 'Client']
    },
    categories() {
      return [...new Set(this.items.map(item => item.category))]
    },
    filteredItems() {
      return this.items.filter(item => {
        if (this.filters.search) {
          const search = this.filters.search.toLowerCase()
          if (
            !item.name.toLowerCase().includes(search) &&
            !item.category.toLowerCase().includes(search) &&
            !item.expense_role.toLowerCase().includes(search)
          ) {
            return false
          }
        }

        if (
          this.filters.expense_role &&
          item.expense_role.toLowerCase() !==
            this.filters.expense_role.toLowerCase()
        ) {
          return false
        }

        return true
      })
    },
  },
  data: function () {
    return {
      filters: {
        business_model_id: this.businessModelId,
        expense_role: null,
        search: null,
      },
      items: [],
      expenseRoles: [
        { name: 'Business', value: 'business' },
        { name: 'Client', value: 'client' },
      ],
    }
  },
  mounted() {
    this.fetchData()
    this.fetchChannels()
  },
  methods: {
    ...mapActions('expenseTypes', [
      'fetchAllExpenseTypes',
      'removeExpenseType',
    ]),
    ...mapActions('reservation', ['fetchChannels']),
    fetchData() {
      this.fetchAllExpenseTypes(this.filters).then(data => {
        if (this.businessModelId) {
          this.items = data.items.filter(
            item => item.business_model_id === this.businessModelId
          )
        } else {
          this.items = data.items
        }
      })
    },
    showExpenseTypeModal(item = null) {
      const props = {
        categories: this.categories,
        expenseRoles: this.expenseRoles,
        afterSave: this.fetchData,
        expenseType: item,
        businessModelId: this.businessModelId,
      }
      this.$store.commit('showModal', {
        name: 'ExpenseTypeModal',
        maxWidth: 400,
        props,
      })
    },
    removeSelctedExpenseType(id) {
      this.removeExpenseType(id).then(() => {
        this.fetchData()
      })
    },
  },
  watch: {
    businessModelId: {
      handler(newVal) {
        this.filters.business_model_id = newVal
        this.fetchData()
      },
      immediate: true,
    },
  },
}
</script>
