<template>
  <v-card class="res-calendar pa-0 elevation-0" depressed>
    <div v-if="addOwnerVacation" class="text-end">
      <reservation-create-modal :is-owner-vacation="true" class="ml-auto">
        <template #customBtn="{ on }">
          <v-btn color="primary" outlined small class="mb-2" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('Owner vacation') }}
          </v-btn>
        </template>
      </reservation-create-modal>
    </div>
    <v-dialog v-if="editDate" v-model="editDate" persistent max-width="300px">
      <v-flex xs12>
        <v-card class="pa-4">
          <v-layout wrap align-center class="grey--text">
            <v-flex xs12 mb-3 justify-center class="bb-1">
              <v-btn
                fab
                dark
                small
                color="teal"
                class="float-right"
                @click="closePriceChange"
              >
                <v-icon dark>close</v-icon>
              </v-btn>
              <div class="center-text">
                Changing the price for <br />
                <span class="bolder">{{ editDate.date }}</span>
              </div>
              <v-layout wrap></v-layout>
              <v-flex>
                <v-text-field
                  v-model="editDate.currentPrice"
                  label="Current Price"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  v-model="editDate.newPrice"
                  label="New Price"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 offset-xs6>
                <v-btn color="blue darken-1" text @click.native="savePrice"
                  >Save
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-dialog>
    <DayPriceInfo v-if="currentDate" :current-date="currentDate" />
    <full-calendar id="reg-cal" ref="calendar" :options="config">
      <template #eventContent="{ event, isStart }">
        <div
          class="event-reservation overflow-hidden"
          :class="{ 'mx-6': isStart }"
        >
          <span class="white rounded-xl source-icon">
            <v-icon small>
              ${{
                $vuetify.icons.values[
                  event.extendedProps.resSource
                    .toLowerCase()
                    .replace(/[. ]/g, '')
                ]
                  ? `$${event.extendedProps.resSource
                      .toLowerCase()
                      .replace(/[. ]/g, '')}`
                  : '$manual'
              }}
            </v-icon>
          </span>
          <span class="black--text ml-2">{{ event.title }}</span>
        </div>
      </template>
      <template #dayCellContent="{ date }">
        <div class="text-end blocked">
          <v-tooltip v-if="isOverridden(date)" bottom open-delay="200">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="info"
                target="_blank"
                icon
                x-small
                class="manual-override"
                v-on="on"
              >
                <v-icon class="" color="error lighten-2" x-small
                  >mdi-marker</v-icon
                >
              </v-btn>
            </template>
            <span>Manual Override</span>
          </v-tooltip>

          <v-tooltip bottom color="darkpurple" min-width="300">
            <template #activator="{ on }">
              <div
                v-show="showPrice(date)"
                :class="'black--text'"
                class="price-tag text-body-2"
                v-on="{ on }"
              >
                <span
                  v-if="isBlocked(date)"
                  class="white rounded-xl source-icon px-1"
                >
                  <v-icon small>fas fa-lock</v-icon>
                </span>
                <span v-else>{{ dayContent(date) }}</span>
              </div>
            </template>
            <DayPriceInfo
              :rate="hasPricing(date)"
              :date="formatDateRate(date)"
            />
          </v-tooltip>
          <v-tooltip v-if="note(date)" top>
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="info-tag pointer"
                dark
                v-bind="attrs"
                small
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>
              {{ note(date) }}
            </span>
          </v-tooltip>
          <span class="grey--text text--darken-1 text-caption">{{
            $moment(date).format('DD')
          }}</span>
        </div>
      </template>
    </full-calendar>
  </v-card>
</template>

<script>
import filter from 'lodash/filter'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import DayPriceInfo from 'components/day-price-info'

import debounce from 'lodash/debounce'
import getOr from 'lodash/fp/getOr'
import { mapState, mapGetters } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ReservationCreateModal from 'components/reservation/reservation-create-modal.vue'

export default {
  name: 'ReservationCalendar',
  components: {
    ReservationCreateModal,
    DayPriceInfo,
    FullCalendar,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, FormattersMixin],
  props: [
    'extCal',
    'slimVersion',
    'addOnRes',
    'hideHeader',
    'monthly',
    'tab',
    'calendar',
    'addOwnerVacation',
  ],
  mounted() {
    const calendarApi = this.$refs.calendar.getApi()
    this.$emit('update:calendar', calendarApi)

    this.setupHover()
    this.debouncer = debounce(this.getCalStats, 1000)

    $('#reg-cal button.fc-button-primary').on('click', () => {
      this.setupHover()
      if (!this.slimVersion) {
        this.debouncer.call(this)
      }
    })
    if (this.addOnRes && calendarApi) {
      calendarApi.gotoDate(this.addOnRes.check_in)
    }
    if (!this.slimVersion) {
      this.$store.dispatch('getPricingMonthlyStats', {
        date: calendarApi.view.title,
      })
    }
    setTimeout(() => {
      calendarApi.updateSize()
    }, 800)
  },
  computed: {
    ...mapState(['currentPricing', 'currentListing']),
    ...mapGetters(['weekStartsAt']),
    ownerIncomeFormula() {
      return (
        this.currentListing.owners_income_formula ||
        this.$store.state.app.configuration.owners_income_formula
      )
    },

    config() {
      return {
        timezone: this.currentListing.timezone,
        height: 540,
        firstDay: this.weekStartsAt,
        plugins: [dayGridPlugin, interactionPlugin],
        aspectRatio: 4,
        initialView: 'dayGridMonth',
        events: this.events,
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        eventBackgroundColor: '#e1bee7',
        eventBorderColor: '#e1bee7',
        dayCellClassNames({ isToday }) {
          if (isToday) {
            return 'info lighten-5 today-lane'
          }
          return ''
        },
        eventClassNames: 'pl-1',
        headerToolbar: !this.hideHeader
          ? {
              left: 'prev,next today',
              center: 'title',
              right: '',
            }
          : false,
      }
    },
    currentReservation() {
      const id = this.chosenReservationId
      if (id) {
        if (id === this.addOnRes?.id) return this.addOnRes
        let calendar = this.extCal || this.$store.state.currentListing.calender
        return filter(calendar, reservation => reservation.id === id)[0] || {}
      }
      return {}
    },
    currentListingId() {
      return this.currentListing.id
    },
    events() {
      let eventList = []
      let calendar = this.extCal || this.$store.state.currentListing.calender

      if (calendar) {
        calendar.forEach(day => {
          if (!(this.addOnRes && this.addOnRes.id === day.id)) {
            const incomeValue = this.incomeFormula(day)?.toFixed()

            eventList.push({
              icon: 'address-book',
              title:
                day.source === 'Owner'
                  ? 'Owner Vacation'
                  : this.toAmount(incomeValue) +
                    (day.status === 'canceled' ? ' (c)' : ''),
              start: this.parseDateTZ(day.check_in, 'YYYY-MM-DD'),
              end: this.parseDateTZ(day.check_out, 'YYYY-MM-DD'),
              resSource: day.source.split('.')[0],
              className: `addon-${day.status}`,
              reservationID: day.id,
            })
          }
        })
      }
      if (this.addOnRes) {
        let day = this.addOnRes
        eventList.push({
          icon: 'address-book',
          title: day.status,
          start: this.parseDateTZ(day.check_in, 'YYYY-MM-DD'),
          end: this.parseDateTZ(day.check_out, 'YYYY-MM-DD'),
          resSource: day.source.split('.')[0],
          className: `addon-${day.status}`,
          reservationID: day.id,
        })
      }
      return eventList
    },
  },
  methods: {
    hasPricing(date) {
      if (this.currentListing.days_rates) {
        return this.currentListing.days_rates[this.formatDateRate(date)]
      }
    },
    formatDateRate(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    dateHasEvent(date) {
      if (this.calendar) {
        let allEvents = []
        allEvents = this.calendar.currentData.calendarOptions.events
        return allEvents.some(v => {
          return this.parseDate(v.start) <= date && date < this.parseDate(v.end)
        })
      }
      return false
    },
    isFuture(date) {
      return date >= this.$moment().utc().format('YYYY-MM-DD')
    },
    showPrice(date) {
      const day = this.$moment(date).format('YYYY-MM-DD')
      return (
        this.currentListing.days_rates &&
        this.currentListing.days_rates[day] &&
        this.isFuture(day) &&
        !this.dateHasEvent(day)
      )
    },
    isBlocked(date) {
      const rate =
        this.currentListing.days_rates[this.$moment(date).format('YYYY-MM-DD')]
      return rate && rate.status === 'unavailable'
    },
    isOverridden(date) {
      if (this.isInvestor) return false
      const rate =
        this.currentListing.days_rates[this.$moment(date).format('YYYY-MM-DD')]
      return rate && rate.manual_override
    },
    dayContent(date) {
      const rate =
        this.currentListing.days_rates[this.$moment(date).format('YYYY-MM-DD')]
      if (rate) {
        if (rate.status === 'unavailable') {
          return 'Blocked'
        }
        if (rate.price) {
          return this.toAmount(rate.price, 0)
        }
      }
      return ''
    },
    note(date) {
      const dateObj =
        this.currentListing.days_rates[this.$moment(date).format('YYYY-MM-DD')]
      if (dateObj && dateObj.note) {
        return dateObj.note
      }
      return null
    },
    onEventClick({ event }) {
      this.chosenReservationId = event.extendedProps.reservationID
      this.$store.commit('showModal', {
        name: 'ReservationCardModal',
        props: {
          reservation: this.currentReservation,
          timezone: this.$store.state.currentListing.timezone,
        },
        isPersistent: false,
      })
      this.dialog = true
    },
    getCalStats() {
      if (this.calendar) {
        const [month, year] = this.calendar.view.title.split(' ')

        this.$store.dispatch('updateStats', {
          id: this.$route.params.id,
          month,
          year,
        })
      }
    },
    markSuspicious(val) {
      this.currentReservation.is_suspicious = val
      this.save()
    },
    incomeFormula(day) {
      const VALUES = {
        client_profit: 'client_profit',
        client_price: 'client_price',
        fare_accommodation: 'fare_accommodation',
        net_income: 'net_income',
        price_total: 'price',
      }
      return this.ownerIncomeFormula
        ? day[VALUES[this.ownerIncomeFormula]]
        : day.fare_accommodation
    },
    setupHover() {
      if (
        window.$store.state.currentPricing &&
        window.$store.state.currentPricing.activated
      ) {
        // let self = this
        // $('.fc-day-top')
        //   .mouseover(function() {
        //     const checkedDate =
        //       window.$store.state.currentPricing.days_rates[
        //         $(this).data('date')
        //       ]
        //     if (checkedDate) {
        //       self.currentDate = $(this).data('date')
        //       $(this).css('z-index', 10000)
        //       $('.tooltipevent').fadeIn('500')
        //       $('.tooltipevent').fadeTo('10', 1.9)
        //     }
        //   })
        //   .mousemove(function(e) {
        //     let moveLeft = 90
        //     let moveTop = 165
        //     if (
        //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        //         navigator.userAgent
        //       )
        //     ) {
        //       moveLeft = 120
        //       moveTop = 180
        //     }
        //     $('.tooltipevent').css(
        //       'top',
        //       $(e.currentTarget).offset().top - moveTop
        //     )
        //     $('.tooltipevent').css(
        //       'left',
        //       $(e.currentTarget).offset().left - moveLeft
        //     )
        //   })
        //   .mouseout(function() {
        //     $(this).css('z-index', 8)
        //     self.currentDate = null
        //   })
      }
    },

    avatarImg(guest) {
      if (guest && guest.picture) {
        return guest.picture
      } else {
        return '/no_image_available.jpg'
      }
    },
    savePrice() {
      let data = new FormData()
      if (this.editDate.date) {
        data.append('date', this.editDate.date)
      }
      if (this.editDate.newPrice) {
        data.append('new_price', this.editDate.newPrice)
      }

      data.append('listing_id', this.$store.state.currentListing.id)

      axios
        .post('/request-price-change', data)
        .then(() => {
          this.$store.dispatch('changeListing', {
            listingId: this.$store.state.currentListing.id,
          })
          this.editDate = null
        })
        .catch(error => {
          this.editDate = null
          alert(error)
        })
    },
    closePriceChange() {
      this.editDate = null
    },
  },
  watch: {
    currentListingId() {
      this.$store.dispatch('getPricingMonthlyStats', {
        date: this.calendar.view.title,
      })
      this.debouncer.call(this)
    },
    config() {
      const calendar = this.calendar || this.$refs.calendar.getApi()
      calendar.updateSize()
    },
    monthly(val) {
      const calendar = this.calendar || this.$refs.calendar.getApi()
      if (val) {
        calendar.updateSize()
      }
    },
    tab(val) {
      if (val === 'stats') {
        const calendar = this.calendar || this.$refs.calendar.getApi()
        setTimeout(() => {
          calendar.updateSize()
        }, 500)
      }
    },
  },
  data() {
    return {
      editDate: null,
      override: false,
      currentDate: null,
      dialog: false,
      chosenReservationId: null,
    }
  },
}
</script>
<style scoped>
#reg-cal >>> .fc-daygrid-day {
  background-color: #e7e7e7;
  border-color: #ddd;
}
#reg-cal >>> .fc-daygrid-event {
  position: relative;
  transform: skew(-22deg) translateX(0px);
  margin: 7px -13px 0 22px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#reg-cal >>> .event-reservation {
  transform: skew(22deg);
}

#reg-cal >>> .fc-daygrid-event.addon-reserved {
  background-color: #cbb5ff !important;
}

#reg-cal >>> .fc-daygrid-event.addon-inquiry {
  background-color: #c3d3de !important;
}

#reg-cal >>> .fc-daygrid-event.addon-confirmed {
  background-color: #95deb0 !important;
}

#reg-cal >>> .fc-daygrid-event.addon-canceled {
  background-color: #de98a5 !important;
}

#reg-cal >>> .fc-event-start {
  margin-left: 40px !important;
}

#reg-cal >>> .fc-event-end {
  margin-right: -40px !important;
}
.source-icon {
  padding: 3px;
}

.price-tag {
  position: absolute;
  bottom: 6px;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
}
.info-tag {
  position: absolute;
  bottom: 2px;
  right: 0;
}
.manual-override {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
</style>
