const menuItems = [
  {
    text: 'Owners',
    icon: '$owner',
    submenu: [
      {
        text: 'Listings',
        value: '/dashboard/listings',
        allowed_roles: [
          'admin',
          'investor',
          'property-manager',
          'coordinator',
          'accountant',
          'field-pm',
        ],
        icon: 'mdi-home-variant-outline',
        demo_only: true,
      },
      {
        text: 'Portfolio',
        icon: 'mdi-briefcase-outline',
        value: '/dashboard/portfolio',
        module: 'ops',
        allowed_roles: ['admin', 'investor', 'property-manager', 'field-pm'],
      },
    ],
  },
  {
    text: 'Operations',
    icon: '$operations',
    submenu: [
      {
        text: 'Field Ops',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'cleaning-supervisor',
          'cleaning-manager',
          'property-manager',
          'coordinator',
          'hotel-reception',
          'accountant',
          'contractor',
          'cleaner',
          'field-pm',
          'pools-manager',
          'repairs-manager',
          'landscaping-manager',
        ],
        module: 'ops',
        icon: 'mdi-account-hard-hat-outline',
        value: '/dashboard/service-calls',
        demo_only: true,
      },
      {
        text: 'Office Tasks',
        icon: 'mdi-office-building-cog-outline',
        value: '/dashboard/taskim',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-agent',
          'sales-manager',
          'property-manager',
          'coordinator',
          'accountant',
        ],
        module: 'ops',
      },
      {
        text: 'IoT',
        abilities: ['iot-management'],
        module: 'iot',
        icon: 'mdi-router-wireless',
        value: '/dashboard/iot',
      },
      {
        text: 'Storage',
        allowed_roles: [
          'admin',
          'cleaning-manager',
          'cleaning-supervisor',
          'property-manager',
          'coordinator',
          'accountant',
          'field-pm',
        ],
        isPrivate: true,
        icon: 'mdi-warehouse',
        module: 'projects',
        value: '/dashboard/inventory',
      },
      {
        text: 'Projects',
        allowed_roles: ['admin', 'property-manager', 'coordinator', 'field-pm'],
        isPrivate: true,
        icon: 'mdi-briefcase-check-outline',
        abilities: ['projects'],
        module: 'projects',
        value: '/dashboard/listings-setup',
      },
      {
        text: 'Automated Tasks',
        allowed_roles: ['admin', 'property-manager'],
        abilities: ['auto-rules-manager'],
        icon: 'mdi-robot',
        value: '/dashboard/auto-tasks',
      },
    ],
  },
  {
    text: 'Guests',
    icon: '$guest_fill',
    submenu: [
      {
        text: 'CRM',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
        ],
        module: 'comm',
        abilities: ['crm-management'],
        abilityOrRole: true,
        icon: 'mdi-account-outline',
        value: '/dashboard/guest-experience',
      },
      {
        text: 'Experience',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
          'field-pm',
        ],
        module: 'guest-exp',
        icon: 'mdi-emoticon-outline',
        value: '/dashboard/guest-mgmt',
      },
      {
        text: 'Guest Book',
        icon: 'mdi-book-open-outline',
        value: '/dashboard/guest-book',
        allowed_roles: ['admin'],
        module: 'ops',
      },
      {
        text: 'Automated Messages',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
        ],
        icon: 'mdi-robot',
        value: '/dashboard/auto-messages',
      },
    ],
  },
  {
    text: 'Marketing',
    icon: '$marketing',
    submenu: [
      {
        text: 'Channels',
        allowed_roles: ['admin'],
        icon: 'mdi-access-point-network',
        value: '/dashboard/cm',
        module: 'channel-manager',
      },
      {
        text: 'Reviews',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'property-manager',
          'coordinator',
        ],
        module: 'comm',
        icon: 'mdi-star-outline',
        value: '/dashboard/reviews-dashboard',
      },
      {
        text: 'Pricing',
        icon: 'mdi-currency-usd',
        value: '/dashboard/pricing-panel',
        allowed_roles: ['admin'],
        abilities: ['pricing-manager'],
        module: 'pricing',
      },
      {
        text: 'Multi calendar',
        allowed_roles: [
          'admin',
          'communication-manager',
          'communication-agent',
          'sales-manager',
          'sales-agent',
          'cleaning-supervisor',
          'cleaning-manager',
          'cleaner',
          'property-manager',
          'coordinator',
          'field-pm',
          'investor',
        ],
        icon: 'mdi-calendar-multiselect',
        value: '/dashboard/multi-calendar',
      },
    ],
  },
  {
    text: 'Accounting',
    icon: '$accounting_fill',
    submenu: [
      {
        text: 'Payables',
        icon: 'mdi-cash-register',
        value: '/dashboard/payables',
        module: 'accounting',
        allowed_roles: ['admin', 'accountant', 'property-manager'],
      },
      {
        text: 'Bank Feeds',
        icon: 'mdi-bank',
        value: '/dashboard/bank-feeds',
        module: 'accounting',
        allowed_roles: ['admin', 'accountant', 'property-manager'],
      },
      {
        text: 'Ledger',
        icon: 'mdi-book-open-variant',
        value: '/dashboard/ledger-wallet',
        module: 'accounting',
        allowed_roles: ['admin', 'accountant'],
      },
      {
        text: 'Financials',
        icon: 'mdi-cash-multiple',
        value: '/dashboard/financials',
        module: 'accounting',
        allowed_roles: ['admin', 'accountant', 'property-manager'],
      },
    ],
  },
  {
    text: 'Configuration',
    icon: '$configuration',
    submenu: [
      {
        text: 'Settings',
        allowed_roles: [
          'admin',
          'property-manager',
          'coordinator',
          'communication-manager',
          'communication-agent',
          'sales-agent',
          'sales-manager',
        ],
        demo_only: true,
        abilities: [
          'estimates-config',
          'users-management',
          'real-estate-finance',
          'pricing-manager',
          'channel-management',
        ],
        icon: '$settings_fill',
        value: '/dashboard/management',
      },
      {
        text: 'AI',
        allowed_roles: ['admin'],
        abilities: ['ai-config'],
        module: 'ai',
        icon: '$ai',
        value: '/dashboard/ai-config',
      },
    ],
  },
  {
    text: 'Feeds',
    icon: 'mdi-pound',
    value: '/dashboard/feeds',
    allowed_roles: ['admin'],
    module: 'ops',
  },
  {
    text: 'Analytics',
    isPrivate: true,
    allowed_roles: [
      'admin',
      'field-pm',
      'property-manager',
      'coordinator',
      'hotel-reception',
      'communication-manager',
      'sales-manager',
    ],
    demo_only: true,
    icon: 'mdi-chart-line',
    abilities: [
      'bi-reviews',
      'bi-cleaning',
      'bi-revenue',
      'bi-accounting',
      'bi-comm',
      'bi-pm',
      'bi-invest',
      'bi-inventory',
    ],
    module: 'bi',
    value: '/dashboard/performance',
  },
  {
    text: 'Dev Tools',
    allowed_roles: ['admin'],
    abilities: ['super-admin', 'view-logs'],
    icon: 'mdi-tools',
    value: '/dashboard/dev-tools',
  },
  {
    text: 'Agents',
    icon: 'mdi-account-tie',
    value: '/dashboard/agent-reservations',
    allowed_roles: ['admin', 'agent'],
    module: 'ops',
  },
]

export default menuItems
