<template>
  <v-card>
    <conversation-info-title title="Payment" />
    <v-divider />
    <v-card-text>
      <div class="mb-4">
        <p class="text-center primary--text">Invoice Items</p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reservation.invoice_items" :key="item.id">
                <td>{{ item.title }}</td>
                <td>{{ toMoney(item.amount) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="reservation.payment_items.length" class="mb-4">
        <p class="text-center primary--text">Payment Items</p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">type</th>
                <th class="text-left">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reservation.payment_items" :key="item.id">
                <td>{{ item.is_auth ? 'Auth' : 'Payment' }}</td>
                <td>{{ item.status.replace('AUTHORIZATION_HOLD_', '') }}</td>
                <td>{{ toMoney(item.amount) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="disputes.length" class="mb-4">
        <p class="text-center primary--text">Disputes</p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Amount</th>
                <th class="text-left">Status</th>
                <th class="text-left">Has evidence</th>
                <th class="text-left">Created</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in disputes" :key="item.id">
                <td>{{ toMoney(item.amount / 100) }}</td>
                <td>{{ item.status }}</td>
                <td>
                  <v-icon
                    v-if="item.evidence_details.has_evidence"
                    color="success"
                    >check</v-icon
                  >
                  <v-icon v-else color="error">close</v-icon>
                </td>
                <td>{{ item.created | moment('DD/MM/YYYY HH:mm') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="refunds.length" class="mb-4">
        <p class="text-center primary--text">Refunds</p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Amount</th>
                <th class="text-left">Done By</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in refunds" :key="item.id">
                <td>{{ toMoney(item.amount / 100) }}</td>
                <td>{{ item.metadata?.done_by }}</td>
                <td>{{ item.created | moment('DD/MM/YYYY') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="reservation.payment_intents.length" class="mb-4">
        <p class="text-center primary--text">Payment intents</p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Type</th>
                <th class="text-left">Amount</th>
                <th class="text-left">Status</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reservation.payment_intents" :key="item.id">
                <td class="text-capitalize">
                  {{ item.metadata['type'].substr(0, 7) }}
                </td>
                <td>{{ toMoney(item.amount / 100) }}</td>
                <td
                  :class="[
                    'text-capitalize',
                    'caption',
                    'mr-2',
                    item.status === 'succeeded' ||
                    item.status === 'requires_capture'
                      ? 'green--text'
                      : 'red--text',
                  ]"
                >
                  {{ formatStatus(item.status) }}
                </td>
                <td>
                  <RefundDialog
                    v-if="
                      (isAdmin || hasAbility('refund')) &&
                      item.metadata['type'] === 'accommodation' &&
                      item.status === 'succeeded' &&
                      isRefundable(item)
                    "
                    type="refund"
                    :intent-id="item.payment_intent_id"
                    :total="item.amount / 100"
                  />
                  <span
                    v-if="
                      item.metadata['type'] === 'accommodation' &&
                      item.status === 'succeeded' &&
                      !isRefundable(item)
                    "
                    class="text-caption green--text"
                  >
                    Fully refunded
                  </span>
                  <RefundDialog
                    v-if="
                      (isAdmin ||
                        hasAbility('refund') ||
                        isCommunicationManager) &&
                      item.metadata['type'] === 'deposit' &&
                      item.status === 'requires_capture'
                    "
                    type="capture"
                    :intent-id="item.payment_intent_id"
                    :total="item.amount / 100"
                  />
                  <charge-modal
                    v-if="
                      ((isCommunicationAgent || managementRoles) &&
                        item.metadata['type'] === 'accommodation' &&
                        item.status === 'requires_source') ||
                      item.status === 'requires_payment_method'
                    "
                    :intent="item"
                    :guest-email="guestEmail"
                    :guest="reservation.guest"
                    :balance="reservation.balance_due"
                    @charge-success="onChargeSuccess"
                  />
                  <span
                    v-if="
                      item.metadata['type'] === 'deposit' &&
                      item.status === 'succeeded'
                    "
                    class="text-caption green--text"
                  >
                    Captured({{ toMoney(item.amount_received / 100) }})
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="isMerchantInquiry">
        <div class="text-caption error--text">{{ intentError }}</div>
        <ConfirmationModal
          text="Are you sure you want to create new payment link for this reservation?"
          @action="createIntent"
        >
          <template #activator="slotData">
            <v-btn color="primary" v-on="slotData.on">
              Create payment link
            </v-btn>
          </template>
        </ConfirmationModal>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import RefundDialog from 'components/stripe/refund-dialog'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import ChargeModal from 'components/common/charge-modal'
import get from 'lodash/fp/get'

export default {
  components: {
    ChargeModal,
    ConversationInfoTitle,
    ConfirmationModal,
    RefundDialog,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['reservation', 'conversationId'],
  data() {
    return {
      copied: {},
      intentError: '',
    }
  },
  computed: {
    guestEmail() {
      return get('emails.0', this.guest)
    },
    isMerchantInquiry() {
      return (
        this.reservation.status === 'inquiry' && this.reservation.is_merchant
      )
    },
    portalDomain() {
      return 'https://guest.boomnow.com'
    },
    refunds() {
      return this.getReservationRefunds(this.reservation.payment_intents)
    },
    disputes() {
      return this.getReservationDisputes(this.reservation.payment_intents)
    },
  },
  methods: {
    isRefundable(intent) {
      const { amount_captured, amount_refunded } = intent.charges.data[0]
      return amount_refunded < amount_captured
    },
    onCopy(el) {
      this.$copyText(`${this.portalDomain}/checkout/${el}`)
    },
    onChargeSuccess() {
      this.$store.dispatch('getChatConversation', this.conversationId)
    },
    createIntent() {
      this.$store
        .dispatch('createReservationIntent', {
          reservationId: this.reservation.id,
        })
        .then(({ data }) => {
          if (data.message) {
            this.intentError = data.message
          }
        })
    },
  },
}
</script>
