<template>
  <v-container v-if="listingTask" class="d-flex justify-center">
    <v-sheet max-width="800">
      <div class="text-h6 pa-4">Service Call Report</div>
      <v-divider />
      <v-row class="px-6">
        <v-col cols="12">
          <info-box
            title="Listing"
            class="flex primary--text"
            :value="listingNickname"
          />
        </v-col>
        <v-col cols="6">
          <info-box
            title="Description"
            class="flex"
            :value="listingTask.description"
          />
        </v-col>
        <v-col cols="6">
          <info-box title="Status" class="flex" :value="listingTask.status" />
        </v-col>
        <v-col v-if="listingTask.owner_approved_by_id" cols="6">
          <info-box
            title="Owner"
            class="flex"
            :value="listingTask.owner_approved_name"
          />
        </v-col>
        <v-col v-if="listingTask.owner_approved_at" cols="6">
          <info-box
            title="Owner approved at"
            class="flex"
            :value="parseDate(listingTask.owner_approved_at)"
          />
        </v-col>
      </v-row>
      <v-divider />
      <div v-if="listingTask.desc_images.length" class="pa-6">
        <div class="font-weight-medium">Media</div>
        <gallery
          :thumbnails="true"
          :cols="4"
          :images="listingTask.desc_images"
          :readonly="true"
        />
      </div>
      <v-divider v-show="listingTask.desc_images.length" />
      <div class="pa-6">
        <expense-card-info
          :done-at="parseDate(doneAt)"
          :hours="listingTask.total_hours"
          :material-cost="materialsSum"
          :management-fee="listingTask.management_fee"
          :labor-cost="listingTask.amount"
        />
      </div>
      <v-divider />
      <div v-if="materialsSum" class="pa-6">
        <div class="d-flex justify-space-between pb-2">
          <span class="text-subtitle-1 font-weight-medium">Materials</span>
          <span class="text-subtitle-1">{{ toMoney(materialsSum) }}</span>
        </div>
        <div class="pl-3">
          <div
            v-for="(material, ind) in listingTask.material_expenses"
            :key="ind"
            class="d-flex justify-space-between"
          >
            <span class="text-capitalize">{{ material.description }}</span>
            <span class="text-capitalize">{{ toMoney(material.amount) }}</span>
          </div>
        </div>
      </div>
      <v-divider v-if="materialsSum" />

      <div v-if="!isEmpty(listingTask.sub_tasks)" class="pa-6">
        <div class="font-weight-medium pb-4">Subtasks:</div>
        <expense-card-subtasks :list="listingTask.sub_tasks" />
      </div>
      <v-row v-else class="pa-6">
        <v-col cols="12" sm="6" class="d-flex">
          <div
            v-if="
              !isEmpty(listingTask.before_images) || listingTask.before_comments
            "
            class="flex"
          >
            <p class="font-weight-medium">Before job:</p>
            <p>{{ listingTask.before_comments }}</p>
            <gallery
              :thumbnails="true"
              :images="listingTask.before_images"
              :readonly="true"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex">
          <div
            v-if="
              !isEmpty(listingTask.after_images) || listingTask.after_comments
            "
            class="flex"
          >
            <p class="font-weight-medium">After job:</p>
            <p>{{ listingTask.after_comments }}</p>
            <gallery
              :thumbnails="true"
              :images="listingTask.after_images"
              :readonly="true"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <div v-if="!isEmpty(allImages)" class="py-6 px-8">
        <p class="font-weight-medium">Receipt images:</p>
        <gallery
          :thumbnails="true"
          :images="allImages"
          :cols="4"
          :readonly="true"
        />
      </div>
      <div v-if="isPendingApproval && !approved" class="d-flex">
        <v-btn
          color="error"
          :disabled="rejected"
          :rounded="false"
          class="flex ma-2"
          @click="reject"
          >Reject</v-btn
        >
        <v-btn
          color="success"
          :rounded="false"
          :disabled="rejected"
          class="flex ma-2"
          :loading="fetching"
          @click="approveTask"
          >Approve</v-btn
        >
      </div>
      <div
        v-else-if="listingTask.owner_approved_at"
        class="text-subtitle-1 text-center font-weight-medium pa-3"
      >
        Task approved and ready for work!
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Gallery from 'components/common/gallery.vue'
import ExpenseCardSubtasks from 'components/expenses/expense-card-subtasks.vue'
import ExpenseCardInfo from 'components/expenses/expense-card-info.vue'
import InfoBox from 'components/common/info-box.vue'
import Common_functions from 'components/mixins/common_functions'
import { sumBy } from 'lodash/fp'
import get from 'lodash/fp/get'
import FormattersMixin from 'components/mixins/formatters-mixin'
import axios from 'axios'

export default {
  name: 'ListingTaskReport',
  mixins: [Common_functions, FormattersMixin],
  data() {
    return {
      approved: false,
      fetching: false,
    }
  },
  components: {
    InfoBox,
    ExpenseCardInfo,
    ExpenseCardSubtasks,
    Gallery,
  },
  methods: {
    async approveTask() {
      this.fetching = true
      const { uid } = this.$route.query
      await this.$store.dispatch('ownerApproval', {
        id: this.listingTask.id,
        user_id: uid,
      })
      this.approved = true
      this.fetching = false
      await this.getTask()
    },
    async getTask() {
      await this.$store.dispatch('getListingTaskReport', { id: this.taskId })
    },
    reject() {
      this.$store.commit('showModal', {
        name: 'UpdateFieldsModal',
        props: {
          onConfirm: async fields => {
            await axios.post(`/api/listing-tasks/${this.taskId}/owner-reject`, {
              status: 'Rejected',
              topic: 'owner approval',
              ...fields,
            })
            await this.getTask()
          },
          item: { address: 'Reject' },
          fields: [
            {
              text: 'Reason',
              value: 'reason',
              fieldType: 'textarea',
              cols: 12,
            },
          ],
        },
      })
    },
  },
  computed: {
    ...mapState(['currentlistingTask']),
    listingTask() {
      return this.currentlistingTask
    },
    rejected() {
      return this.listingTask.status === 'Rejected'
    },
    taskId() {
      return this.$route.params.id
    },
    allImages() {
      const materialsImages = this.listingTask.material_expenses.flatMap(
        m => m.images
      )
      return this.listingTask.receipt_images.concat(materialsImages)
    },
    isPendingApproval() {
      return this.listingTask.status === 'Owner Approval'
    },
    doneAt() {
      return this.parseDate(this.listingTask.done_at)
    },
    listingNickname() {
      return get('mini_extra_data.listing_name', this.listingTask)
    },
    materialsSum() {
      return sumBy('amount', this.listingTask.material_expenses)
    },
  },
  async mounted() {
    await this.getTask()
  },
}
</script>

<style scoped></style>
