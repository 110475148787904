<template>
  <v-container>
    <v-card>
      <v-card-title class="d-block pa-6">
        <div class="d-flex align-center mb-6">
          <div class="text-h5 font-weight-bold primary--text">
            General Ledger
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isSuperAdmin"
            outlined
            color="error"
            :loading="resetting"
            class="ml-4"
            @click="confirmReset"
          >
            Reset All Transactions
          </v-btn>
          <v-btn
            outlined
            color="primary"
            :loading="exporting"
            class="ml-4"
            @click="exportToExcel"
          >
            <v-icon left>mdi-download</v-icon>
            Export to Excel
          </v-btn>
        </div>

        <v-row>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Total Debit</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.total_debit) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Total Credit</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.total_credit) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined class="total-card">
              <v-card-text class="pa-4">
                <div class="text-subtitle-2 mb-2">Balance</div>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ $options.filters.currency(meta.balance) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="filters.dateFrom"
                  label="From Date"
                  readonly
                  outlined
                  dense
                  clearable
                  v-on="on"
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateFrom"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="2">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="filters.dateTo"
                  label="To Date"
                  readonly
                  outlined
                  dense
                  clearable
                  v-on="on"
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateTo"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="filters.accountId"
              :items="accounts"
              item-text="name"
              item-value="id"
              label="Account"
              outlined
              dense
              clearable
              class="account-select"
              @change="handleFilterChange"
            >
              <template #item="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
              <template #selection="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
            </v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-model="filters.accountType"
              :items="accountTypes"
              label="Account Type"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-select
              v-model="filters.sourceType"
              :items="sourceTypes"
              label="Source"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="transactions"
        :loading="loading"
        :options="paginationServer"
        :server-items-length="paginationServer.count"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 200],
        }"
        class="elevation-1"
        @pagination="paginationChanged($event)"
      >
        <template #item.posted_at="{ item }">
          {{ new Date(item.posted_at).toLocaleDateString() }}
        </template>

        <template #item.account="{ item }">
          <v-btn
            small
            text
            color="primary"
            @click="viewAccount(item.ledger_account)"
          >
            {{ item.ledger_account.name }}
          </v-btn>
        </template>

        <template #item.amount="{ item }">
          <span :class="getAmountClass(item)">
            {{ $options.filters.currency(item.amount) }}
          </span>
        </template>

        <template #item.transaction_type="{ item }">
          <v-chip
            small
            :color="getTransactionTypeColor(item.transaction_type)"
            :text-color="getTransactionTypeTextColor(item.transaction_type)"
            label
            outlined
          >
            {{ item.transaction_type }}
          </v-chip>
        </template>

        <template #item.transactionable_type="{ item }">
          <v-chip small>
            {{ formatTransactionableType(item.transactionable_type) }}
          </v-chip>
        </template>

        <template #item.reference="{ item }">
          <v-btn
            v-if="item.reference_id"
            small
            text
            color="primary"
            @click="viewReference(item)"
          >
            {{ getReferenceText(item) }}
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="showResetDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h5">Reset All Transactions?</v-card-title>
          <v-card-text>
            This will delete all existing ledger transactions and regenerate
            them for the past 2 months. This action cannot be undone.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showResetDialog = false">Cancel</v-btn>
            <v-btn
              color="error"
              :loading="resetting"
              @click="resetTransactions"
            >
              Reset Transactions
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import LedgerFunctions from 'components/mixins/ledger-functions-mixin'
import axios from 'axios'
import Toaster from '@/utils/toaster'
import { downloadFile } from '@/utils/common-utils'

export default {
  name: 'GeneralLedger',
  mixins: [CommonFunctions, LedgerFunctions],
  // ... mixins and filters stay the same ...

  data: () => ({
    search: '',
    loading: false,
    transactions: [],
    fromMenu: false,
    toMenu: false,
    paginationServer: {
      page: 1,
      itemsPerPage: 10,
      count: 0,
    },
    filters: {
      dateFrom: null,
      dateTo: null,
      accountId: null,
      accountType: null,
      sourceType: null,
    },
    headers: [
      { text: 'Date', value: 'posted_at' },
      { text: 'Account', value: 'account' },
      { text: 'Description', value: 'description' },
      { text: 'Type', value: 'transaction_type' },
      { text: 'Amount', value: 'amount' },
      { text: 'Source Type', value: 'transactionable_type' },
      { text: 'Reference', value: 'reference' },
    ],
    accountTypes: [
      { text: 'Asset', value: 'asset' },
      { text: 'Liability', value: 'liability' },
      { text: 'Equity', value: 'equity' },
      { text: 'Income', value: 'income' },
      { text: 'Expense', value: 'expense' },
    ],
    sourceTypes: [], // Will be populated from API
    meta: {
      total_debit: 0,
      total_credit: 0,
      balance: 0,
    },
    resetting: false,
    showResetDialog: false,
    accounts: [],
    exporting: false,
  }),

  watch: {
    options: {
      handler() {
        this.fetchTransactions()
      },
      deep: true,
    },
  },

  computed: {
    isSuperAdmin() {
      return this.$store.state.user.super_admin
    },
  },

  methods: {
    paginationChanged(pagination) {
      if (this.paginationServer.itemsPerPage !== pagination.itemsPerPage) {
        this.paginationServer.itemsPerPage = pagination.itemsPerPage
        this.paginationServer.page = 1
        this.fetchTransactions()
      } else if (this.paginationServer.page !== pagination.page) {
        this.paginationServer.page = pagination.page
        this.fetchTransactions()
      }
    },

    handleFilterChange() {
      this.paginationServer.page = 1
      this.fetchTransactions()
    },

    async fetchAccounts() {
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts
      } catch (error) {
        console.error('Error fetching accounts:', error)
      }
    },

    async fetchTransactions() {
      this.loading = true
      try {
        const params = {
          page: this.paginationServer.page,
          per_page: this.paginationServer.itemsPerPage,
          search: this.search,
          ...this.filters,
        }

        const response = await axios.get('/api/ledger-accounts/transactions', {
          params,
        })
        this.transactions = response.data.transactions
        this.paginationServer.count = response.data.pagination.count
        this.meta = response.data.meta
        this.updateSourceTypes(response.data.source_types)
      } catch (error) {
        console.error('Error fetching transactions:', error)
        this.$store.dispatch('showError', 'Failed to load transactions')
      } finally {
        this.loading = false
      }
    },

    updateSourceTypes(types) {
      this.sourceTypes = types.map(type => ({
        text: this.formatTransactionableType(type),
        value: type,
      }))
    },

    confirmReset() {
      this.showResetDialog = true
    },

    async resetTransactions() {
      this.resetting = true
      try {
        await axios.post('/api/ledger-accounts/reset_transactions')
        this.showResetDialog = false
        Toaster.show([
          {
            type: 'success',
            text: 'Reset initiated. Transactions will be regenerated shortly.',
          },
        ])
        // Optionally refresh your data after a short delay
        setTimeout(() => {
          this.fetchTransactions()
        }, 5000)
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.resetting = false
      }
    },

    async exportToExcel() {
      this.exporting = true
      try {
        const response = await axios.get('/api/ledger-accounts/export', {
          params: {
            date_from: this.filters.dateFrom,
            date_to: this.filters.dateTo,
            account_type: this.filters.accountType,
            account_id: this.filters.accountId,
            source_type: this.filters.sourceType,
          },
          responseType: 'blob',
        })

        const filename =
          response.headers['content-disposition']
            ?.split('filename=')[1]
            ?.replace(/["']/g, '') || 'general_ledger.xlsx'

        downloadFile(response.data, filename)
      } catch (error) {
        console.error('Error exporting report:', error)
        this.$store.dispatch('showError', 'Failed to export report')
      } finally {
        this.exporting = false
      }
    },
  },

  created() {
    this.fetchAccounts()
    this.fetchTransactions()
  },
}
</script>

<style scoped>
.v-card__title {
  border-bottom: 1px solid #e0e0e0;
}

.account-text {
  font-size: 13px;
}

.account-select :deep(.v-select__selection) {
  font-size: 13px !important;
}

.total-card {
  height: 112px;
  border-color: var(--v-primary-base) !important;
}
</style>
