<template>
  <v-layout>
    <form-dialog
      v-model="dialog"
      :title="title"
      max-width="500px"
      @submit="save"
      @close="reInitPayment"
    >
      <template #activator="{ on }">
        <v-btn class="dark-cyan" dark v-on="on">{{ title }}</v-btn>
      </template>

      <v-expansion-panels v-if="!isEmpty(investors)">
        <v-expansion-panel :key="1233">
          <v-expansion-panel-header> Payments info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-row v-for="investor in investors" :key="investor.id" wrap>
                  <v-col cols="6" class="bb-1 purple-icon bolder br-1"
                    >{{ investor.first_name }}
                  </v-col>
                  <v-col cols="6" class="bb-1 purple-icon bolder"
                    >{{ investor.last_name }}
                  </v-col>
                  <v-col cols="8" class="purple-icon bolder br-1"
                    >{{ investor.email }}
                  </v-col>
                  <v-col cols="4" class="purple-icon bolder"
                    >{{ investor.bank_accounts_number }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn
              v-if="lastPayment"
              class="purple-icon mt-3"
              @click="copyPaymentInfo(lastPayment)"
              >Use last payment info
            </v-btn>
            <v-btn
              v-if="lastReferralPayment"
              class="cyan-icon mt-3"
              @click="copyPaymentInfo(lastReferralPayment)"
              >Use last referrer info
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-select
        v-model="payment.title"
        :items="titles"
        outlined
        dense
        :label="$t('Payment Title')"
        @change="getPaymentInfo"
      />
      <v-menu
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        required
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="payment.start_period"
            outlined
            dense
            :label="
              $t(
                'Start Period (the month the payment this will be attached to)'
              )
            "
            prepend-inner-icon="event"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="payment.start_period"
          :max="$moment.utc().endOf('month').toISOString()"
          type="month"
          no-title
          reactive
          @change="startDateChanged"
          @click:year="startDateYearChanged"
        />
      </v-menu>
      <v-menu
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        required
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="payment.end_period"
            outlined
            dense
            :label="$t('End Period')"
            prepend-inner-icon="event"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="payment.end_period"
          :max="$moment.utc().endOf('month').toISOString()"
          type="month"
          no-title
          reactive
          @change="endDateChanged"
          @click:year="endDateYearChanged"
        />
      </v-menu>
      <date-picker
        v-model="payment.paid_at"
        :hide-details="false"
        :label="$t('Paid at')"
      />
      <div v-if="paymentHint" class="comp-wrapper mb-6">
        <span class="grey-text"
          >Need to transfer {{ toMoney(paymentHint) }}</span
        ><br />
        <v-btn
          v-if="Number(paymentHint) !== 0"
          class="purple-icon"
          @click="balanceRoll"
          >Create Balance rollover
        </v-btn>
      </div>
      <v-text-field
        ref="paymentAmount"
        v-model="payment.amount"
        type="number"
        prepend-inner-icon="money"
        :label="$t('Amount')"
        outlined
        dense
        :rules="[required, isPositive]"
      />
      <v-select
        v-model="payment.type_of_payment"
        :items="paymentType"
        outlined
        dense
        :label="$t('Payment Type')"
      />

      <v-text-field
        v-model="payment.recipient_name"
        outlined
        dense
        :label="$t('Recipient Name')"
      />
      <div v-if="payment.type_of_payment === 'Credit'">
        <v-text-field
          v-model="payment.designedvr_account"
          :label="$t('From Account')"
          outlined
          dense
          :rules="[required]"
        />
        <v-text-field
          v-model="payment.recipient_account"
          :label="$t('Recipient Account')"
          outlined
          dense
          :rules="[required]"
        />
        <v-text-field
          v-model="payment.transaction_id"
          outlined
          dense
          :label="$t('Transaction ID')"
        />
      </div>
      <v-text-field
        v-if="payment.type_of_payment === 'Check'"
        v-model="payment.check_number"
        outlined
        dense
        :label="$t('Check Number')"
      />

      <v-textarea v-model="payment.note" outlined dense :label="$t('Note')" />
    </form-dialog>
  </v-layout>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import find from 'lodash/find'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'

export default {
  components: { DatePicker, FormDialog },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin, FormattersMixin],
  props: ['type', 'title'],
  data() {
    return {
      dialog: false,
      date: null,
      startMenu: false,
      endMenu: false,
      paymentHint: null,
      paymentLoading: false,
      payment: {
        title: 'Listing Profits',
        paid_by_us: true,
        paid_at: this.$moment.utc().format('YYYY-MM-DD'),
        start_period: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
        end_period: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
        amount: 1,
      },
      paymentType: ['Credit', 'ACH', 'Cash', 'Check', 'Zelle'],
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getPaymentInfo()
        this.reInitPayment()
      }
    },
  },
  computed: {
    currentListing() {
      return this.$store.state.currentListing
    },
    listingId() {
      return this.$route.params.id
    },
    lastPayment() {
      if (this.currentListing.recent_payments) {
        return find(
          this.currentListing.recent_payments,
          payment => payment.title === 'Listing Profits'
        )
      }
      return null
    },
    lastReferralPayment() {
      if (this.currentListing.recent_payments) {
        return find(
          this.currentListing.recent_payments,
          payment => payment.title === 'Referrer commission'
        )
      }
      return null
    },
    investors() {
      let res = []
      if (this.$store.state.currentEditListing) {
        res = this.$store.state.currentEditListing.investors
      }
      return res || []
    },
    titles() {
      if (this.type === 'ClientPayment') {
        return ['Listing Profits', 'Referrer commission', 'Piggy bank']
      } else {
        return ['Design', 'Brokerage']
      }
    },
  },
  methods: {
    copyPaymentInfo(oldPayment) {
      this.payment.title = oldPayment.title
      this.payment.type_of_payment = oldPayment.type_of_payment
      this.payment.designedvr_account = oldPayment.designedvr_account
      this.payment.transaction_id = oldPayment.transaction_id
      this.payment.check_number = oldPayment.check_number
      this.payment.recipient_name = oldPayment.recipient_name
      this.payment.recipient_account = oldPayment.recipient_account
      this.payment.note = oldPayment.note
    },
    startDateChanged(val) {
      this.startMenu = false
      this.payment.start_period = val.toString()
      this.getPaymentInfo()
    },
    startDateYearChanged(val) {
      this.payment.start_period = val.toString() + '-01'
      this.getPaymentInfo()
    },
    endDateChanged(val) {
      this.endMenu = false
      this.payment.end_period = val.toString()
      this.getPaymentInfo()
    },
    endDateYearChanged(val) {
      this.payment.end_period = val.toString() + '-01'
      this.getPaymentInfo()
    },
    balanceRoll() {
      this.paymentLoading = true

      axios
        .post('/api/payments/roll', {
          ...this.payment,
          listing_id: this.listingId,
        })
        .then(() => {
          this.dialog = null
          this.$store.dispatch('changeEditListing', this.listingId)
          this.paymentLoading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    reInitPayment() {
      this.payment = {
        title: 'Listing Profits',
        paid_by_us: true,
        start_period: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
        end_period: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
        paid_at: this.$moment.utc().format('YYYY-MM-DD'),
        type_of_payment: null,
        designedvr_account: null,
        transaction_id: null,
        check_number: null,
        note: null,
        recipient_name: null,
        amount: 1,
      }
      this.paymentHint = null
    },
    getPaymentInfo() {
      axios
        .get(`/api/payment-info`, {
          params: {
            ...this.payment,
            listing_id: this.listingId,
          },
        })
        .then(response => {
          this.paymentHint = response.data.payload.data
          this.payment.amount = this.paymentHint
        })
        .catch(() => ({}))
    },
    save() {
      this.paymentLoading = true
      this.payment['type'] = this.type
      if (this.type === 'ClientPayment') {
        this.payment['listing_id'] = this.listingId
      }

      axios
        .post('/api/payments/', this.payment)
        .then(() => {
          this.paymentLoading = false
          this.dialog = null
          if (this.type === 'ClientPayment') {
            this.$store.dispatch('changeEditListing', this.listingId)
          } else {
            this.$store.dispatch(
              'getManagementStats',
              new Date().toISOString().substr(0, 7)
            )
          }
          this.$store.dispatch('changeEditListing', this.listingId)
          this.payment = {
            paid_by_us: true,
          }
          this.reInitPayment()
        })
        .catch(error => {
          this.paymentLoading = false
          alert(error)
        })
    },
  },
}
</script>
